import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger, SplitText);
const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeigh;

ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

gsap.from("#navigation-slide-toggle", {
  x: 100,
});

document.querySelectorAll(".ACF-quote").forEach(function (container) {
  const text = new SplitText(container.querySelector('blockquote'), { type: "words,lines" });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: false, // Add a small delay of scrolling and animation. `true` is direct
      start: "-70% center", // Can be top, center, bottom 
      end: "20% top", // Can be top, center, bottom
      // pin: true,
    }
  });

  timeline.from(text.words, {
    y: 50,
    // duration: 1,
    rotation: 45,
    ease: "elastic.out(1, 0.65)",
    opacity: 0,
    stagger: {
      amount: 1.8,
      // from: "random",
    }
  });

});

//--------------------------------//
// Parallax
//--------------------------------//
gsap.to(".shapes svg", {
  scrollTrigger: {
    scrub: 0.5
  },
  y: (index, target) => -ScrollTrigger.maxScroll(window) * gsap.utils.random([0.6, 0.3, 0.8, 0.1]),
  ease: "none"
});
// Parallax -------------------//