//------------------------------------------------------//
// Slide menu 🎛️ toggle  
//------------------------------------------------------//
const navigationSlideToggle = document.querySelector('#navigation-slide-toggle');
const navigationSlide = document.querySelector('#navigation-slide');
const body = document.querySelector('body');

// On 🐭 click add class to body and toggle 🦻 ARIA lables
navigationSlideToggle.addEventListener('click', () => {
  body.classList.toggle('showNavigationSlide');
  navigationSlideToggle.setAttribute('aria-expanded', navigationSlideToggle.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
});
// END Slide menu 🎛️ toggle  -------------------------------------//

//------------------------------------------------------//
// On browser 📜 scroll full window height  
//------------------------------------------------------//
window.onscroll = function (ev) {
  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    // you're at the bottom of the page
    body.classList.add('showScrolled');
  } else {
    body.classList.remove('showScrolled');
  }
};
// END On browser 📜 scroll full window height  -------------------------------------//

//--------------------------------//
// Add Pound # sign to headers  
//--------------------------------//
const alloyMain = document.querySelector('main');
const alloyAllHeaders = alloyMain.querySelectorAll('h1, h2, h3, h4');
// convert string to URL ready sting
function convertToSlug(Text) {
  return Text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

alloyAllHeaders.forEach((item, index) => {
  // Add ID to all found headers
  item.setAttribute('id', `${convertToSlug(item.innerText)}`);
  // Create <a href></a> element
  const anchor = document.createElement("a");
  anchor.classList.add('alloy-headline-anchor');
  anchor.href = `#${convertToSlug(item.innerText)}`;
  anchor.textContent = `#`;
  // Add <a href></a> to header
  item.append(anchor);
})
// END Add Pound # sign to headers  --------------//
